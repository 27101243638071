import { Text, cn } from '@typedream/ui';
import { MdCheck } from 'react-icons/md';
import Image from 'next/image';
import styles from './LoginRightSection.module.css';

function LoginRightSection() {
  return (
    <div className={styles.root}>
      <Image
        className={styles.onboardingImage}
        src="/images/login-illust-ai-builder-02.png"
        width={888}
        height={589}
        alt="Login page illustration"
      />
      <div className="absolute left-[112px] top-[155px] pl-16 w-full max-w-[60%]">
        <div className={cn('inline-flex px-3 py-1 mb-5 select-none', styles.onboardingAIBadge)}>
          <span className="text-sm font-medium text-transparent bg-clip-text bg-gradient-to-r from-feature-ai-gradient-1 via-feature-ai-gradient-2 to-feature-ai-gradient-3">
            AI Powered
          </span>
        </div>
        <Text as="h2" variant="main" size="2xl" weight="bold">
          A complete toolkit to launch your
          <br />
          business online 🚀
        </Text>
        <ul className="space-y-2 mt-5 list-none">
          <li className="flex items-center space-x-2">
            <MdCheck className="text-accent" aria-hidden />
            <Text variant="secondary" size="lg" weight="medium">
              Generate sitemap & wireframes with AI
            </Text>
          </li>
          <li className="flex items-center space-x-2">
            <MdCheck className="text-accent" aria-hidden />
            <Text variant="secondary" size="lg" weight="medium">
              Edit, customize, and publish your site
            </Text>
          </li>
          <li className="flex items-center space-x-2">
            <MdCheck className="text-accent" aria-hidden />
            <Text variant="secondary" size="lg" weight="medium">
              Accept payments, add forms, and more
            </Text>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LoginRightSection;
